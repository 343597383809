import React, { useRef } from 'react';
import styled, { CSSProperties } from 'styled-components';
import {
    Form,
    Input as InputComponent,
    Checkbox as CheckboxComponent,
    Select as SelectComponent,
    TextArea as TextAreaComponent,
    Dropdown as DropdownComponent,
    Label,
    Icon,
    SelectProps,
    CheckboxProps,
} from 'semantic-ui-react';
import Help from '../Help';
import Counter from '../Counter';
import { WrappedFieldProps } from 'redux-form';
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic';

const inputMixin = `
    max-width: 500px;
`;

const StyledFormField = styled(Form.Field)`
    display: ${(props) => (props.inline ? 'inline-block' : 'block')};
    max-width: 460px;
    ${(props) => {
        return (
            props.clearable
             ? `
             &&& .input > input {
                padding-right: 80px!important;
            }
            &&& .icon.close.clearable {
                right: 40px;
                border-radius: 0;
            }
             `
          : undefined
        )
    }
        
    }
    
`;

const StyledInputComponent = styled(InputComponent)`
    ${inputMixin};
`;
const StyledTextAreaComponent = styled(TextAreaComponent)`
    ${inputMixin};
`;
const StyledSelectComponent = styled(SelectComponent)`
    ${inputMixin};
`;
const StyledLabel = styled.label`
    ${inputMixin};
`;

interface FieldProps extends WrappedFieldProps {
    required?: boolean;
    width?: SemanticWIDTHS;
    label?: string;
    help?: string;
    type?: string;
    inline?: boolean;
    defaultChecked?: boolean;
    name: string;
}

const getDefaultIcon = (type?: string) => {
    switch (type) {
        case 'date':
            return 'calendar alternate outline';
        default:
            return undefined;
    }
};

interface InputFieldProps extends FieldProps {
    prefix?: any;
    suffix?: any;
    icon?: any;
    labelPosition?: any;
    iconPosition: string;
    action?: any;
    inputProps?: any;
    inputRef?: React.Ref<any>;
}

export const InputField: React.FC<InputFieldProps & { clearable?: boolean}> = ({
    input,
    label,
    help,
    required,
    width,
    inline,
    prefix,
    suffix,
    icon,
    type,
    labelPosition,
    iconPosition,
    action,
    clearable = false,
    inputProps,
    meta: { touched, submitFailed, error },
    inputRef,
    ...rest
}) => {
    const innerInputRef = useRef<HTMLInputElement>();

    let iconName;

    if (icon !== null) {
        iconName = icon || getDefaultIcon(type);
    }

    return (
        <StyledFormField
            error={!!((touched || submitFailed) && error)}
            required={required}
            width={width}
            inline={inline}
            clearable={clearable}
        >
            {label && (
                <StyledLabel>
                    {label}
                    {help && <Help content={help} />}
                </StyledLabel>
            )}
            {/* defaultValue={indefined} to make possible use defaultValue prop for redux-form's Field */}
            <StyledInputComponent
                labelPosition={suffix ? 'right' : prefix ? 'left' : undefined}
                iconPosition={iconName && (iconPosition || 'left')}
                required={required}
                action={action}
                {...input}
                {...rest}
                defaultValue={undefined}
            >
                {prefix && <Label>{prefix}</Label>}
                {iconName && <Icon name={iconName} />}
                {input.value && iconName && clearable ? (
                    <Icon
                        name="close"
                        onClick={(ev: any) => {
                            input.onChange(" ");
                            input.onChange("");
                            // @ts-ignore
                            input.onFocus();

                            if(innerInputRef?.current) {
                                innerInputRef.current.value= "";
                                innerInputRef.current.focus();
                            }
                        }}
                        link
                        className="clearable"
                    />
                ) : null}
                <input type={type} {...inputProps} ref={inputRef || innerInputRef} />
                {action}
                {suffix && <Label>{suffix}</Label>}
            </StyledInputComponent>
            {touched && error && (
                <Label basic color="red" pointing>
                    {error}
                </Label>
            )}
        </StyledFormField>
    );
};

export const TextAreaField: React.FC<FieldProps> = ({
    input,
    label,
    help,
    required,
    width,
    inline,
    meta: { touched, submitFailed, error },
    ...rest
}) => (
    <StyledFormField
        error={!!((touched || submitFailed) && error)}
        required={required}
        width={width}
        inline={inline}
    >
        {label && (
            <StyledLabel>
                {label}
                {help && <Help content={help} />}
            </StyledLabel>
        )}
        <StyledTextAreaComponent required={required} {...input} {...rest} />
        {touched && error ? (
            <Label basic color="red" pointing>
                {error}
            </Label>
        ) : null}
    </StyledFormField>
);

interface SelectFieldProps extends FieldProps, SelectProps {
    clearable?: boolean;
    style?: CSSProperties;
}

const iconStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    right: '2em',
    lineHeight: 1,
    zIndex: 1,
};

const containerStyle = {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
};

export const SelectField: React.FC<SelectFieldProps> = ({
    input,
    label,
    help,
    required,
    width,
    inline,
    options,
    meta: { touched, submitFailed, error },
    clearable,
    style = {},
    ...custom
}) => (
    <StyledFormField error={!!((touched || submitFailed) && error)} width={width} inline={inline}>
        {label && (
            <StyledLabel>
                {label}
                {help && <Help content={help} />}
            </StyledLabel>
        )}
        <div
            // @ts-ignore
            style={containerStyle}
        >
            {clearable && input.value && (
                <Icon link name="close" style={iconStyle} onClick={() => input.onChange(null)} />
            )}
            <StyledSelectComponent
                value={input.value}
                options={options}
                onChange={(e: any, data: any) => input.onChange(data.value)}
                style={{ width: '100%', ...style }}
                required={required}
                {...custom}
            />
        </div>
        {touched && error ? (
            <Label basic color="red" pointing>
                {error}
            </Label>
        ) : null}
    </StyledFormField>
);

export const Dropdown = ({ input, required, options, ...rest }: SelectFieldProps) => (
    <DropdownComponent
        search
        value={input.value}
        required={required}
        options={options}
        onChange={(e: any, data: any) => input.onChange(data.value)}
        {...rest}
    />
);

interface CheckboxFieldProps extends WrappedFieldProps, CheckboxProps {}

export const Checkbox = ({ input, label, help, width, ...custom }: CheckboxFieldProps) => (
    <CheckboxComponent
        id={input.name}
        checked={!!input.value}
        onChange={(event, data) => input.onChange(data.checked)}
        label={
            <label htmlFor={input.name}>
                {label}
                {help && <Help content={help} />}
            </label>
        }
        {...custom}
    />
);

export const CheckboxField = ({ input, label, help, width, ...custom }: CheckboxFieldProps) => (
    <Form.Field width={width} inline>
        <CheckboxComponent
            id={input.name}
            checked={!!input.value}
            onClick={(event, data) => input.onChange(data.checked)}
            label={
                <label htmlFor={input.name}>
                    {label}
                    {help && <Help content={help} />}
                </label>
            }
            {...custom}
        />
    </Form.Field>
);

export const CheckboxGroupField = ({ input, options }: CheckboxFieldProps) => (
    <div>
        {options.map((option: any, index: number) => (
            <Form.Field key={index}>
                <CheckboxComponent
                    id={`${input.name}[${index}]`}
                    checked={(input.value || []).indexOf(option.value) !== -1}
                    onClick={(event, data) => {
                        const newValue = [...(input.value || [])];
                        if (data.checked) {
                            newValue.push(option.value);
                        } else {
                            newValue.splice(newValue.indexOf(option.value), 1);
                        }

                        return input.onChange(newValue);
                    }}
                    label={option.text}
                />
            </Form.Field>
        ))}
    </div>
);

export const CounterField = ({
    input,
    label,
    help,
    width,
    meta: { touched, error },
    ...custom
}: FieldProps) => {
    return (
        <Form.Field width={width} inline>
            <Counter
                value={input.value}
                onDecrement={() => {
                    input.onChange(input.value - 1);
                }}
                onIncrement={() => {
                    input.onChange(input.value + 1);
                }}
                {...custom}
            />
        </Form.Field>
    );
};

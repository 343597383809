import { AnyAction } from 'redux';
import { put, select, call, take } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { getFormValues } from 'redux-form';
import { push } from 'connected-react-router';
import { apiClient, throwSubmissionError } from '@/utils';
import { downloadFile } from '@/utils/exportToXLSX';
import { confirmSaga } from './confirm';
import { getTranslate } from 'react-localize-redux';
import Notifications from 'react-notification-system-redux';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import { differenceWith, isEqual } from 'lodash';
import { AccessKeyType } from '@/types';

const MAX_INACTIVE_USERS_FOR_DELETE = 2000;

export function* searchCustomers({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/customers', {
            params: { search: payload.value, IncludeCustomFieldValues: true },
        });

        yield put({ type: actions.SEARCH_CUSTOMERS.SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: actions.SEARCH_CUSTOMERS.FAILURE, payload: error });
    }
}

export function* fetchCustomers({ payload }: AnyAction) {
    try {
        const filters: { [key in string]: any} = yield select(getFormValues('customerFilters'));
        const sort: { [key in string]: any} = yield select((state) => state.customers.sort);
        const OrderBy = sort.by && sort.ascending ? sort.by : undefined;
        const OrderByDesc = sort.by && !sort.ascending ? sort.by : undefined;
        const response: AxiosResponse = yield apiClient.get('/customers', {
            params: {
                IncludeCustomFieldValues: true,
                ...filters,
                OrderBy,
                OrderByDesc,
                ...payload,
            },
        });

        yield put({ type: actions.FETCH_CUSTOMERS.SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: actions.FETCH_CUSTOMERS.FAILURE, payload: error });
    }
}

export function* sortCustomers() {
    yield put(actions.FETCH_CUSTOMERS.request());
}

export function* createCustomer({ payload }: AnyAction) {
    const create = actions.CREATE_CUSTOMER;
    try {
        const response: AxiosResponse = yield apiClient.post('/customers', { ...payload });

        yield put(create.success(response.data));
        yield take('@@redux-form/SET_SUBMIT_SUCCEEDED');
        yield put(push(`/customers/${response.data.Id}`));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* deleteCustomerAccessKey({ payload }: AnyAction) {
    const edit = actions.EDIT_CUSTOMER;
    const localizeState: RootState['localize'] = yield select((state) => state.localize);
    const accessKeyTypes: RootState['accessKeyTypes']['data'] = yield select((state) => state.accessKeyTypes.data);
    const translate = getTranslate(localizeState);

    const accessKeyType = accessKeyTypes.find((type: AccessKeyType) => type.Id === payload.AccessKeyTypeId);

    const confirmed: boolean = yield call(
        confirmSaga,
        `${translate('customers.confirmAccessKeyDelete', { key: accessKeyType?.KeyType })}`
    );
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.put(`/customers/${payload.CustomerId}`, {
                ...payload,
                    AccessKeysToDelete: [payload],
                    AccessKeys: [],
            });
    
            yield put(edit.success(response.data));
    
            yield put(
                actions.FETCH_CUSTOMERS.request({
                    CustomerId: payload.CustomerId,
                    IncludeAccessKeys: true,
                    IncludeInvoiceAddress: true
                })
            );
        } catch (error) {
            yield put(edit.failure(throwSubmissionError(error)));
        }
    }
}

export function* editCustomer({ payload }: AnyAction) {
    const edit = actions.EDIT_CUSTOMER;
    const customerForm: RootState['form']['customer'] = yield select((state) => state.form.customer);

    const addition = differenceWith(
        customerForm?.values?.AccessKeys,
        // @ts-ignore
        customerForm?.initial?.AccessKeys,
        isEqual
    );

    const isChanged =
        addition.length > 0 &&
        // @ts-ignore
        customerForm.initial.AccessKeys.length - customerForm.values.AccessKeys.length === 0;
    
    const isAdded =
        addition.length > 0 &&
        // @ts-ignore
        customerForm.values.AccessKeys.length - customerForm.initial.AccessKeys.length > 0;
        

    if (isChanged || isAdded) {
        payload = {
            ...payload,
            ...(  'SubscribedToNewsletter' in payload ? { SubscribedToNewsletter: payload.SubscribedToNewsletter === false ? null : payload.SubscribedToNewsletter} : {}),
            AccessKeys: addition,
        };
    } else {
        payload = {
            ...payload,
            ...(  'SubscribedToNewsletter' in payload ? { SubscribedToNewsletter: payload.SubscribedToNewsletter === false ? null : payload.SubscribedToNewsletter} : {}),
            AccessKeys: [],
        };
    }

    try {
        const response: AxiosResponse = yield apiClient.put(`/customers/${payload.Id}`, { ...payload });

        yield put(edit.success(response.data));

        yield put(
            actions.FETCH_CUSTOMERS.request({
                CustomerId: payload.Id,
                IncludeAccessKeys: true,
                IncludeInvoiceAddress: true
            })
        );
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deleteCustomer({ payload, meta: { confirmationMessage } }: AnyAction) {
    const confirmed: string = yield call(confirmSaga, confirmationMessage);

    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/customers/${payload}`);

            yield put(actions.DELETE_CUSTOMER.success(response));
            yield put(push('/customers'));
        } catch (error) {
            yield put(actions.DELETE_CUSTOMER.failure(throwSubmissionError(error)));
        }
    }
}

export function* sendMessageToCustomer({ payload }: AnyAction) {
    try {
        let response: AxiosResponse;
        if (payload.type === 'Email') {
            response = yield apiClient.post('/messages/sendemail', {
                ...payload,
                Receiver: payload.Email,
            });
        } else if (payload.type === 'SMS') {
            response = yield apiClient.post('/messages/sendsms', {
                ...payload,
                Receiver: payload.Phone,
            });
        } else {
            // @ts-ignore
            throw new Error({ message: 'Error' });
        }

        yield put(actions.SEND_MESSAGE_TO_CUSTOMER.success(response));
    } catch (error) {
        yield put(actions.SEND_MESSAGE_TO_CUSTOMER.failure(throwSubmissionError(error)));
    }
}

const gdprToXLSX = (gdpr: any) => {
    try {
        const config: { sheetKey: string; columnKeys: string[] }[] = [
            { sheetKey: 'Customer', columnKeys: Object.keys(gdpr.Customer) },
            { sheetKey: 'Bookings', columnKeys: Object.keys(gdpr.Bookings[0] || {}) },
            { sheetKey: 'CustomerComment', columnKeys: Object.keys(gdpr.CustomerComment[0] || {}) },
            { sheetKey: 'MessageLog', columnKeys: Object.keys(gdpr.MessageLog[0] || {}) },
            { sheetKey: 'NewsletterLog', columnKeys: Object.keys(gdpr.NewsletterLog[0] || {}) },
        ];

        import('xlsx-populate/browser/xlsx-populate-no-encryption').then(
            ({ default: XlsxPopulate }) => {
                XlsxPopulate.fromBlankAsync().then((workbook) => {
                    config.forEach(({ sheetKey, columnKeys }: any, index) => {
                        if (sheetKey === 'Customer') {
                            workbook.sheet(0).name('Customer');
                        } else {
                            workbook.addSheet(sheetKey);
                        }

                        if (columnKeys.length > 0) {
                            workbook
                                .sheet(sheetKey)
                                .cell('A1')
                                // @ts-ignore
                                .value([columnKeys]);

                            if (sheetKey === 'Customer') {
                                workbook
                                    .sheet(sheetKey)
                                    .cell('A2')
                                    // @ts-ignore
                                    .value([columnKeys.map((key) => gdpr[sheetKey][key])]);
                            } else {
                                if (gdpr[sheetKey].length > 0) {
                                    workbook
                                        .sheet(sheetKey)
                                        .cell('A2')
                                        .value(
                                            gdpr[sheetKey].map((row: any) =>
                                                columnKeys.map((key: string) => row[key])
                                            )
                                        );
                                }
                            }
                        }
                    });

                    downloadFile(workbook);
                });
            }
        );
    } catch (e) {
        console.error(e);
    }
};

export function* fetchGDPR({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/gdpr/customers/${payload.customerId}`, {
            params: { CompanyId: payload.companyId },
        });

        gdprToXLSX(response.data);

        yield put(actions.FETCH_GDPR.success(response));
    } catch (error) {
        yield put(actions.FETCH_GDPR.failure(error));
    }
}

export function* fetchInactiveCustomers({ payload }: AnyAction) {
    const localizeState: RootState['localize'] = yield select((state) => state.localize);
    const translate = getTranslate(localizeState);
    try {
        const response: AxiosResponse = yield apiClient.get(`/gdpr/customers/inactive`, {
            params: {
                IncludeCustomerInformation: true,
                ...payload,
            },
        });

        yield put(actions.FETCH_INACTIVE_CUSTOMERS.success({ Results: response.data }));
    } catch (error: any) {
        // TODO: remove this when api handles more than 2000 inactive users GET
        if(error.response.status === 500) {
            yield put(
                Notifications.error({
                    title: translate('customers.deleteInactiveCustomersError') as string
                })
            );
            yield put(actions.FETCH_INACTIVE_CUSTOMERS.success({ Results: []}));
        } else {
            yield put(actions.FETCH_INACTIVE_CUSTOMERS.failure(error));
        }
    }
}

export function* deleteInactiveCustomers({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            yield put({ type: actions.DELETE_INACTIVE_CUSTOMERS_IN_PROGRESS });

            const response: AxiosResponse = yield apiClient.delete(`/gdpr/customers/inactive`, {
                params: payload,
            });
            
            yield put(actions.DELETE_INACTIVE_CUSTOMERS.success(response));
        } catch (error) {
            yield put(actions.DELETE_INACTIVE_CUSTOMERS.failure(error));
        }
    }
}

import React, { Component } from 'react';
import { compose, AnyAction, ActionCreator } from 'redux';
import { connect, ConnectedComponent } from 'react-redux';
import { Loader as Spinner } from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'qs';

import Error from './Error';

export interface RequirementData {
    isLoading: boolean;
    isLoaded: boolean;
    hasError: boolean;
    error: any;
    requestIsHidden: boolean;
}

interface Requirement {
    data: RequirementData;
    action: AnyAction | ActionCreator<any>;
}

interface OwnProps {
    requirements: Requirement[];
    children: any;
    reloadOnHashChange?: boolean;
}

interface Props extends OwnProps, RouteComponentProps, ConnectedComponent<any, any> {}

interface State {
    mounted: boolean;
}

class Loader extends Component<Props, State> {
    static defaultProps = {
        reloadOnHashChange: true,
    };

    state = { mounted: false };

    componentDidMount() {
        this.load();
        this.setState({ mounted: true });
    }

    componentDidUpdate(prevProps: Props) {
        const { reloadOnHashChange, location, requirements, dispatch } = this.props;

        if (prevProps.location.pathname !== location.pathname) {
            this.load();
        } else if (reloadOnHashChange && prevProps.location.hash !== location.hash) {
            this.load();
        } else if (prevProps.location.search !== location.search) {
            const paginatedRequirement = requirements.find(
                (requirement) => typeof requirement.action === 'function'
            );
            if (paginatedRequirement && typeof paginatedRequirement.action === 'function') {
                dispatch(paginatedRequirement.action(this.getQuery()));
            }
        }
    }

    load() {
        const { requirements } = this.props;

        requirements.forEach((requirement) => {
            if (!requirement.data.isLoading) {
                if (requirement.data.hasError) {
                    console.log(requirement.action)
                    console.log('Error from server. Abandoning.');
                } else {
                    if (requirement.action) {
                        if (typeof requirement.action === 'function') {
                            this.props.dispatch(requirement.action(this.getQuery()));
                        } else {
                            this.props.dispatch(requirement.action);
                        }
                    }
                }
            } else {
                console.log('Data is loading. Waiting for results...');
            }
        });
    }

    getQuery() {
        return queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
    }

    render() {
        const { children, requirements } = this.props;
        const { mounted } = this.state;

        for (let i = 0; i < requirements.length; i++) {
            const { isLoading, requestIsHidden } = requirements[i].data;

            if (!mounted || (isLoading && !requestIsHidden)) {
                return <Spinner data-testid="loader" active />;
            } else if (requirements[i].data.hasError) {
                return <Error error={requirements[i].data.error} showReload />;
            }
        }

        return children;
    }
}

export default compose(withRouter, connect())(Loader) as React.ComponentType<OwnProps>;

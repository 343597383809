import { put, call, select, all, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { getCompanyId } from '@/store/reducers/newAuth';
import * as actions from '../actions';
import { apiClient, throwSubmissionError } from '../../utils';
import { confirmSaga } from './confirm';
import { PromoCode } from '@/types';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import { omit } from 'lodash';

export function* promoCodes({ payload }: AnyAction) {
    try {
        const CompanyId: RootState['company']['data']['Id'] = yield select(getCompanyId);
        
        const params = {
            CompanyId,
            IncludeConnectedServices: true,
            IncludeConnectedDaysOfWeek: true,
            CompanyRebateCodes: true,
            ...payload,
        };

        const response: AxiosResponse = yield apiClient.get('/rebatecodes', {
            // We omit Active param because of API doesn't respond to false as it should
            params: !payload.Active ? omit(params, ['Active']) : params,
        });

        yield put(
            actions.FETCH_PROMO_CODES.success({
                ...response.data,
                Results: response.data.Results.map((result: PromoCode) => ({
                    ...result,
                    DaysOfWeek: result.DaysOfWeek.map((day: any) => day.Id),
                })),
            })
        );
    } catch (error) {
        yield put(actions.FETCH_PROMO_CODES.failure(error));
    }
}

export function* createPromoCode(action: AnyAction) {
    const create = actions.CREATE_PROMO_CODE;
    try {
        const response: AxiosResponse = yield apiClient.post(`/rebatecodes`, action.payload);

        yield put(create.success(response));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editPromoCode(action: AnyAction) {
    const edit = actions.EDIT_PROMO_CODE;
    try {
        const response: AxiosResponse = yield apiClient.put(`/rebatecodes/${action.payload.Id}`, action.payload);

        yield put(edit.success(response));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deletePromoCode({ payload, meta = {} }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            let params: {
                ForceDelete?: boolean;
            } = {}

            if(payload.StatusName === 'Off') {
                params['ForceDelete'] = true;
            }

            const response: AxiosResponse = yield apiClient.delete(`/rebatecodes/${payload.Id}`, {
                data: payload,
                params
            });

            yield put(actions.DELETE_PROMO_CODE.success(response));

            if (meta.redirectOnSuccess) {
                yield put(push('/promo-codes'));
            }
        } catch (error) {
            yield put(actions.DELETE_PROMO_CODE.failure(throwSubmissionError(error)));
        }
    }
}

export default function* promoCodesSaga() {
    yield all([
        takeLatest(actions.FETCH_PROMO_CODES.REQUEST, promoCodes),
        takeLatest(actions.CREATE_PROMO_CODE.REQUEST, createPromoCode),
        takeLatest(actions.DELETE_PROMO_CODE.REQUEST, deletePromoCode),
        takeLatest(actions.EDIT_PROMO_CODE.REQUEST, editPromoCode),
    ]);
}

import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Flex } from 'grid-styled';
import {
    Table,
    Image,
    Header,
    Button,
    Menu,
    Icon,
    Dimmer,
    Loader,
    Pagination,
    PaginationProps,
    SemanticICONS,
} from 'semantic-ui-react';
import queryString from 'qs';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';

import Truncate from './Truncate';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import Help from '@/components/Help';
import { getVismaIconName } from '@/utils/common';
import { VismaIcon } from './VismaIcon';

const HeaderCell = styled(Table.HeaderCell)`
    cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')} !important;
    &:hover {
        background-color: ${(props) => (props.onClick ? props.theme.gray100 : 'none')} !important;
    }
    & > span {
        text-decoration: ${(props) => (props.sortable ? 'underline' : 'none')};
    }
`;

const WithBackground = styled.span`
    background-color: #ccc !important;
    *,
    i.icon {
        color: #ccc !important;
    }
`;

const Description = styled.span`
    font-style: italic;
    color: ${(props) => props.theme.gray500};
`;

export interface Column {
    name?: React.ReactNode;
    type?: string;
    renderer?: (rowData: any, rowIndex: number) => React.ReactNode;
    headerRenderer?: Function;
    attribute?: string;
    descriptionAttribute?: string;
    sortingAttribute?: string;
    sortable?: boolean;
    collapsing?: boolean;
    link?: (rowData: any) => string;
    help?: React.ReactNode;
}

export interface Action {
    name?: React.ReactNode;
    onAction: Function;
    color?: SemanticCOLORS;
    icon?: SemanticICONS;
    loading?: boolean;
    primary?: boolean;
}

export interface HeaderActions extends Action {}

interface OwnProps {
    columns: Array<Column>;
    actions?: Array<Action>;
    title?: React.ReactNode;
    data?: Array<any>;
    headerActions?: Array<HeaderActions>;
    hideColumnHeaders?: boolean;
    emptyMessage?: React.ReactNode;
    // Content of cells is one line
    nowrap?: boolean;
    collapsing?: boolean;
    loading?: boolean;
    offset?: number;
    pageSize?: number;
    total?: number;
    attached?: boolean | 'top' | 'bottom';
    style?: React.CSSProperties
}

interface Props extends OwnProps, LocalizeContextProps, RouteComponentProps {}

class DataTable extends Component<Props> {
    static defaultProps = {
        offset: 0,
    };

    setSort = (column: Column) => {
        const params = this.getQuery();

        const sortingAttribute = column.sortingAttribute || column.attribute;

        if (params.OrderBy && params.OrderBy === sortingAttribute) {
            params.OrderByDesc = sortingAttribute;
            delete params.OrderBy;
        } else if (params.OrderByDesc && params.OrderByDesc === sortingAttribute) {
            delete params.OrderBy;
            delete params.OrderByDesc;
        } else {
            params.OrderBy = sortingAttribute as string;
            delete params.OrderByDesc;
        }

        this.props.history.push({
            ...this.props.location,
            search: `?${queryString.stringify({
                ...params,
            })}`,
        });
    };

    setPage = (event: React.MouseEvent, { activePage }: PaginationProps) => {
        const params = this.getQuery();
        const { pageSize } = this.props;

        if (typeof activePage === 'number' && typeof pageSize === 'number') {
            params.Skip = String((activePage - 1) * pageSize || 0);

            this.props.history.push({
                ...this.props.location,
                search: `?${queryString.stringify(params)}`,
            });
        }
    };

    getQuery() {
        return queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
    }

    render() {
        const {
            data,
            actions,
            columns,
            headerActions,
            title,
            offset,
            pageSize,
            total,
            loading,
            translate,
            ...rest
        } = this.props;

        if (!data) return false;

        const query = this.getQuery();

        return (
            <>
                <div
                    style={{
                        overflowX: 'auto',
                        marginBottom: '1em',
                        ...(rest.style ? {...rest.style} : {})
                    }}
                >
                    {title && (
                        <Menu
                            borderless
                            attached="top"
                            style={{
                                width: '100%',
                            }}
                        >
                            <Menu.Item>
                                <Header as="h3">{title}</Header>
                            </Menu.Item>
                            {headerActions && (
                                <Menu.Menu position="right">
                                    <Menu.Item position="right">
                                        <Button.Group>
                                            {headerActions.map((action, index) => (
                                                <Button
                                                    basic
                                                    color={action.color}
                                                    onClick={() => action.onAction()}
                                                    icon={<VismaIcon neutral icon={action.icon} />}
                                                    content={action.name}
                                                    loading={action.loading}
                                                    key={index}
                                                    primary={action.primary}
                                                />
                                            ))}
                                        </Button.Group>
                                    </Menu.Item>
                                </Menu.Menu>
                            )}
                        </Menu>
                    )}
                    <Table
                        compact
                        unstackable
                        attached={this.props.attached}
                        style={{
                            whiteSpace: this.props.nowrap ? 'nowrap' : undefined,
                            width: '100%',
                        }}
                    >
                        <Table.Header>{this.renderHeader(actions, columns)}</Table.Header>
                        <Table.Body>{this.renderRows(columns, data, actions, loading)}</Table.Body>
                    </Table>
                </div>
                {typeof offset !== 'undefined' &&
                    typeof total !== 'undefined' &&
                    offset > -1 &&
                    pageSize &&
                    total > 0 && (
                        <Flex justifyContent="space-between" alignItems="center">
                            <Pagination
                                activePage={parseInt(query.Skip as string) / pageSize + 1 || 1}
                                totalPages={Math.ceil(total / pageSize)}
                                onPageChange={this.setPage}
                            />
                            <div>
                                <strong>
                                    {translate('common.total')}: {total}
                                </strong>
                            </div>
                        </Flex>
                    )}
            </>
        );
    }

    renderHeader(actions?: Action[], columns: Column[] = []) {
        const { hideColumnHeaders } = this.props;
        const query = this.getQuery();

        if (hideColumnHeaders) return false;

        return (
            <Table.Row>
                {columns.map((column, index) => {
                    if (column.headerRenderer) {
                        return <HeaderCell key={index}>{column.headerRenderer()}</HeaderCell>;
                    }

                    const sortingAttribute = column.sortingAttribute || column.attribute;

                    let sorted;

                    if (query.OrderBy && query.OrderBy === sortingAttribute) {
                        sorted = 'ascending';
                    } else if (query.OrderByDesc && query.OrderByDesc === sortingAttribute) {
                        sorted = 'descending';
                    }

                    return (
                        <HeaderCell
                            sortable={column.sortable}
                            onClick={column.sortable ? () => this.setSort(column) : undefined}
                            key={index}
                        >
                            <span>
                                {column.name} {column.help && <Help content={column.help} />}
                            </span>
                            {sorted && ' '}
                            {sorted &&
                                (column.type === 'text' ? (
                                    // @ts-ignore
                                    <Icon name={`sort alphabet ${sorted}`} />
                                ) : (
                                    // @ts-ignore
                                    <Icon name={`sort content ${sorted}`} />
                                ))}
                        </HeaderCell>
                    );
                })}
                {actions && actions.map((action: any, index) => <HeaderCell key={index} />)}
            </Table.Row>
        );
    }

    renderRows(columns: Column[], data: any[], actions?: Action[], loading?: boolean) {
        return data.length !== 0
            ? data.map((rowData, rowIndex) =>
                  this.renderRow(columns, rowData, rowIndex, actions, loading)
              )
            : data.length === 0 && this.renderEmptyRow();
    }

    renderEmptyRow() {
        return (
            <Table.Row warning>
                <Table.Cell
                    colSpan="100%"
                    textAlign="center"
                    style={{ position: 'relative', height: 52 }}
                >
                    {!this.props.loading ? (
                        this.props.emptyMessage || this.props.translate('common.emptyListMessage')
                    ) : (
                        <Dimmer active inverted>
                            <Loader />
                        </Dimmer>
                    )}
                </Table.Cell>
            </Table.Row>
        );
    }

    renderRow(
        columns: Column[] = [],
        rowData: any,
        rowIndex: number,
        actions?: Action[],
        loading?: boolean
    ) {
        return (
            <Table.Row className={rowData.Active === false ? 'inactive' : ''} key={rowIndex}>
                {columns.map((column, index) => {
                    let content = null;
                    if (column.renderer) {
                        content = column.renderer(rowData, rowIndex);
                    } else if (column.type === 'image') {
                        content = this.renderImage(rowData, column);
                    } else {
                        content = this.renderValue(rowData, column);
                    }

                    return (
                        <Table.Cell
                            style={{
                                verticalAlign: 'middle',
                                color: loading ? 'transparent' : undefined,
                            }}
                            key={index}
                            collapsing={column.collapsing || column.type === 'image'}
                        >
                            {!loading && content}
                            {loading && <WithBackground>{content}</WithBackground>}
                        </Table.Cell>
                    );
                })}
                {actions &&
                    actions.map((action, actionIndex) =>
                        rowData.hide !== action.name || !rowData.hide ? (
                            <Table.Cell collapsing key={actionIndex}>
                                {!action.loading && action.icon ? (
                                    <VismaIcon
                                        onClick={() => action.onAction(rowData)}
                                        icon={action.icon}
                                        color={action.color}
                                        title={typeof action.name === 'string' ? action.name : ''}
                                        data-cy={`table-action-${action.name}`.toLowerCase()}
                                    />
                                ) : action.loading ? (
                                    <Loader />
                                ) : null}
                            </Table.Cell>
                        ) : null
                    )}
            </Table.Row>
        );
    }

    renderImage(rowData: any, column: Column) {
        if (!column.attribute) return null;

        let value = get(rowData, column.attribute);

        return <Image src={value || 'assets/image.png'} size="mini" circular />;
    }

    renderValue(rowData: any, column: Column) {
        if (!column.attribute) return null;

        let value = get(rowData, column.attribute);

        if (typeof value === 'boolean') {
            return value ? <Icon name="check" /> : '-';
        }

        return (
            <>
                {column.link ? <Link to={column.link(rowData)}>{value}</Link> : value}
                {column.descriptionAttribute && <br />}
                {column.descriptionAttribute && (
                    <Description>
                        {this.props.loading ? (
                            <WithBackground>
                                <Truncate>{get(rowData, column.descriptionAttribute)}</Truncate>
                            </WithBackground>
                        ) : (
                            <Truncate>{get(rowData, column.descriptionAttribute)}</Truncate>
                        )}
                    </Description>
                )}
            </>
        );
    }
}

export default compose<React.ComponentType<OwnProps>>(withRouter, withLocalize)(DataTable);

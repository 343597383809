import { createSelector } from 'reselect';
import { createListLoadingReducer } from './utils';

export const getPromoCodes = (state: any) => state.promoCodes;

export const getPromoCodeById = (id: string) =>
    createSelector(getPromoCodes, (promoCodes) =>
        promoCodes.isLoaded ? promoCodes.data.find((item: any) => item.Id.toString() === id) : null
    );

export default createListLoadingReducer('PROMO_CODES');
